@charset "UTF-8";
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #045bc1;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #d0021b;
  --orange: #fd7e14;
  --yellow: #f37f29;
  --green: #7ed321;
  --teal: #20c997;
  --cyan: #00d6ff;
  --white: #fff;
  --gray: #7F7F7F;
  --gray-dark: #555555;
  --primary: #045bc1;
  --secondary: #666666;
  --success: #7ed321;
  --info: #00d6ff;
  --warning: #f37f29;
  --danger: #d0021b;
  --light: #fafafa;
  --dark: #1c1c1c;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "PT Serif", Times, "Times New Roman", Georgia, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #666666;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #045bc1;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #023876;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #949494;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 900;
  line-height: 1.2;
  color: #000;
}

h1, .h1 {
  font-size: 3.157rem;
}

h2, .h2 {
  font-size: 2.369rem;
}

h3, .h3 {
  font-size: 1.777rem;
}

h4, .h4 {
  font-size: 1.333rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.75rem;
}

.lead {
  font-size: 1.333rem;
  font-weight: 400;
}

.display-1 {
  font-size: 9.878rem;
  font-weight: 900;
  line-height: 1;
}

.display-2 {
  font-size: 7.427rem;
  font-weight: 900;
  line-height: 1;
}

.display-3 {
  font-size: 5.584rem;
  font-weight: 900;
  line-height: 1;
}

.display-4 {
  font-size: 4.199rem;
  font-weight: 900;
  line-height: 1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 75%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.333rem;
}

.blockquote-footer {
  display: block;
  font-size: 75%;
  color: #949494;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0.1875rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #7F7F7F;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #404040;
  border-radius: 0.1875rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #404040;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #666666;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e1e1e1;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e1e1e1;
}

.table tbody + tbody {
  border-top: 2px solid #e1e1e1;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e1e1e1;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e1e1e1;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #666666;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b9d1ee;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7caadf;
}

.table-hover .table-primary:hover {
  background-color: #a4c4e9;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a4c4e9;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d4d4d4;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #afafaf;
}

.table-hover .table-secondary:hover {
  background-color: #c7c7c7;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c7c7c7;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dbf3c1;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #bce88c;
}

.table-hover .table-success:hover {
  background-color: #cfefac;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #cfefac;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8f4ff;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7aeaff;
}

.table-hover .table-info:hover {
  background-color: #9ff0ff;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #9ff0ff;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcdbc3;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f9bc90;
}

.table-hover .table-warning:hover {
  background-color: #fbccab;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbccab;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2b8bf;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e77b88;
}

.table-hover .table-danger:hover {
  background-color: #eea2ac;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #eea2ac;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfc;
}

.table-hover .table-light:hover {
  background-color: #f1f1f1;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f1f1f1;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bfbfbf;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #898989;
}

.table-hover .table-dark:hover {
  background-color: #b2b2b2;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b2b2b2;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #2b2b2b;
  border-color: #3e3e3e;
}

.table .thead-light th {
  color: #1c1c1c;
  background-color: #f2f2f2;
  border-color: #e1e1e1;
}

.table-dark {
  color: #fff;
  background-color: #2b2b2b;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #3e3e3e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.45em + 1.6rem + 2px);
  padding: 0.8rem 0.8rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #404040;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.1875rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #404040;
}

.form-control:focus {
  color: #404040;
  background-color: #fff;
  border-color: #499bfb;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 91, 193, 0.25);
}

.form-control::placeholder {
  color: #7F7F7F;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f2f2f2;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #404040;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.8rem + 1px);
  padding-bottom: calc(0.8rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.45;
}

.col-form-label-lg {
  padding-top: calc(1.2rem + 1px);
  padding-bottom: calc(1.2rem + 1px);
  font-size: 1.333rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.4rem + 1px);
  padding-bottom: calc(0.4rem + 1px);
  font-size: 0.75rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.8rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.45;
  color: #666666;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.8rem + 2px);
  padding: 0.4rem 0.4rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.form-control-lg {
  height: calc(1.5em + 2.4rem + 2px);
  padding: 1.2rem 1.2rem;
  font-size: 1.333rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #949494;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #7ed321;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.45;
  color: #fff;
  background-color: rgba(126, 211, 33, 0.9);
  border-radius: 0.1875rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #7ed321;
  padding-right: calc(1.45em + 1.6rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%237ed321' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3625em + 0.4rem) center;
  background-size: calc(0.725em + 0.8rem) calc(0.725em + 0.8rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #7ed321;
  box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.45em + 1.6rem);
  background-position: top calc(0.3625em + 0.4rem) right calc(0.3625em + 0.4rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #7ed321;
  padding-right: calc(0.75em + 3rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23555555' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.8rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%237ed321' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.8rem/calc(0.725em + 0.8rem) calc(0.725em + 0.8rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #7ed321;
  box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #7ed321;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #7ed321;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #7ed321;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #97e245;
  background-color: #97e245;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #7ed321;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #7ed321;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #7ed321;
  box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #d0021b;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.45;
  color: #fff;
  background-color: rgba(208, 2, 27, 0.9);
  border-radius: 0.1875rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d0021b;
  padding-right: calc(1.45em + 1.6rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d0021b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d0021b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3625em + 0.4rem) center;
  background-size: calc(0.725em + 0.8rem) calc(0.725em + 0.8rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #d0021b;
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.45em + 1.6rem);
  background-position: top calc(0.3625em + 0.4rem) right calc(0.3625em + 0.4rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d0021b;
  padding-right: calc(0.75em + 3rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23555555' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.8rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d0021b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d0021b' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.8rem/calc(0.725em + 0.8rem) calc(0.725em + 0.8rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #d0021b;
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d0021b;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d0021b;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #d0021b;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fd0826;
  background-color: #fd0826;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d0021b;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d0021b;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d0021b;
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 700;
  color: #666666;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.8rem 2.5rem;
  font-size: 1rem;
  line-height: 1.45;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #666666;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 91, 193, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #045bc1;
  border-color: #045bc1;
}

.btn-primary:hover {
  color: #fff;
  background-color: #03499c;
  border-color: #03438f;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #03499c;
  border-color: #03438f;
  box-shadow: 0 0 0 0.2rem rgba(42, 116, 202, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #045bc1;
  border-color: #045bc1;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #03438f;
  border-color: #033e83;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(42, 116, 202, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #535353;
  border-color: #4d4d4d;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #535353;
  border-color: #4d4d4d;
  box-shadow: 0 0 0 0.2rem rgba(125, 125, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #464646;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 125, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #7ed321;
  border-color: #7ed321;
}

.btn-success:hover {
  color: #fff;
  background-color: #6ab21c;
  border-color: #64a71a;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #6ab21c;
  border-color: #64a71a;
  box-shadow: 0 0 0 0.2rem rgba(145, 218, 66, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #7ed321;
  border-color: #7ed321;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #64a71a;
  border-color: #5d9c18;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(145, 218, 66, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #00d6ff;
  border-color: #00d6ff;
}

.btn-info:hover {
  color: #fff;
  background-color: #00b6d9;
  border-color: #00abcc;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #00b6d9;
  border-color: #00abcc;
  box-shadow: 0 0 0 0.2rem rgba(38, 220, 255, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #00d6ff;
  border-color: #00d6ff;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00abcc;
  border-color: #00a1bf;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 220, 255, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #f37f29;
  border-color: #f37f29;
}

.btn-warning:hover {
  color: #fff;
  background-color: #e96b0d;
  border-color: #dd650c;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #e96b0d;
  border-color: #dd650c;
  box-shadow: 0 0 0 0.2rem rgba(245, 146, 73, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #f37f29;
  border-color: #f37f29;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #dd650c;
  border-color: #d1600c;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 146, 73, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #d0021b;
  border-color: #d0021b;
}

.btn-danger:hover {
  color: #fff;
  background-color: #aa0216;
  border-color: #9d0214;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #aa0216;
  border-color: #9d0214;
  box-shadow: 0 0 0 0.2rem rgba(215, 40, 61, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #d0021b;
  border-color: #d0021b;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #9d0214;
  border-color: #910113;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 40, 61, 0.5);
}

.btn-light {
  color: #000;
  background-color: #fafafa;
  border-color: #fafafa;
}

.btn-light:hover {
  color: #000;
  background-color: #e7e7e7;
  border-color: #e1e1e1;
}

.btn-light:focus, .btn-light.focus {
  color: #000;
  background-color: #e7e7e7;
  border-color: #e1e1e1;
  box-shadow: 0 0 0 0.2rem rgba(213, 213, 213, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #000;
  background-color: #fafafa;
  border-color: #fafafa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #e1e1e1;
  border-color: #dadada;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 213, 213, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #1c1c1c;
}

.btn-dark:hover {
  color: #fff;
  background-color: #090909;
  border-color: #030303;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #090909;
  border-color: #030303;
  box-shadow: 0 0 0 0.2rem rgba(62, 62, 62, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #1c1c1c;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #030303;
  border-color: black;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 62, 62, 0.5);
}

.btn-outline-primary {
  color: #045bc1;
  border-color: #045bc1;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #045bc1;
  border-color: #045bc1;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 91, 193, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #045bc1;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #045bc1;
  border-color: #045bc1;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 91, 193, 0.5);
}

.btn-outline-secondary {
  color: #666666;
  border-color: #666666;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #666666;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}

.btn-outline-success {
  color: #7ed321;
  border-color: #7ed321;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #7ed321;
  border-color: #7ed321;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #7ed321;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #7ed321;
  border-color: #7ed321;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.5);
}

.btn-outline-info {
  color: #00d6ff;
  border-color: #00d6ff;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #00d6ff;
  border-color: #00d6ff;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 214, 255, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #00d6ff;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #00d6ff;
  border-color: #00d6ff;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 214, 255, 0.5);
}

.btn-outline-warning {
  color: #f37f29;
  border-color: #f37f29;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #f37f29;
  border-color: #f37f29;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 127, 41, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f37f29;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f37f29;
  border-color: #f37f29;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 127, 41, 0.5);
}

.btn-outline-danger {
  color: #d0021b;
  border-color: #d0021b;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #d0021b;
  border-color: #d0021b;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #d0021b;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d0021b;
  border-color: #d0021b;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.5);
}

.btn-outline-light {
  color: #fafafa;
  border-color: #fafafa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #fafafa;
  border-color: #fafafa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #fafafa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #000;
  background-color: #fafafa;
  border-color: #fafafa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}

.btn-outline-dark {
  color: #1c1c1c;
  border-color: #1c1c1c;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #1c1c1c;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 28, 28, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #1c1c1c;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #1c1c1c;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 28, 28, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #045bc1;
  text-decoration: none;
}

.btn-link:hover {
  color: #023876;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #7F7F7F;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1.2rem 5rem;
  font-size: 1.333rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.4rem 1.2rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.8rem;
  color: #666666;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e1e1e1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.4rem 1.2rem;
  clear: both;
  font-weight: 400;
  color: #666666;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #404040;
  text-decoration: none;
  background-color: #f2f2f2;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #045bc1;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #7F7F7F;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.2rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #7F7F7F;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.4rem 1.2rem;
  color: #666666;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9rem;
  padding-left: 0.9rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 3.75rem;
  padding-left: 3.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.8rem 0.8rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #404040;
  text-align: center;
  white-space: nowrap;
  background-color: #f2f2f2;
  border: 1px solid #e1e1e1;
  border-radius: 0.1875rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 2.4rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.2rem 1.2rem;
  font-size: 1.333rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.8rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.4rem 0.4rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.8rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.45rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.225rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #045bc1;
  background-color: #045bc1;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(4, 91, 193, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #499bfb;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #7bb7fc;
  border-color: #7bb7fc;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #7F7F7F;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f2f2f2;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.225rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #949494 solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.225rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.1875rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #045bc1;
  background-color: #045bc1;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(4, 91, 193, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(4, 91, 193, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(4, 91, 193, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.225rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #949494;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(4, 91, 193, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.45em + 1.6rem + 2px);
  padding: 0.8rem 1.8rem 0.8rem 0.8rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #404040;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23555555' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.8rem center/8px 10px;
  border: 1px solid #e1e1e1;
  border-radius: 0.1875rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #499bfb;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 91, 193, 0.25);
}

.custom-select:focus::-ms-value {
  color: #404040;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.8rem;
  background-image: none;
}

.custom-select:disabled {
  color: #7F7F7F;
  background-color: #f2f2f2;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #404040;
}

.custom-select-sm {
  height: calc(1.5em + 0.8rem + 2px);
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.4rem;
  font-size: 0.75rem;
}

.custom-select-lg {
  height: calc(1.5em + 2.4rem + 2px);
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-left: 1.2rem;
  font-size: 1.333rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.45em + 1.6rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.45em + 1.6rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #499bfb;
  box-shadow: 0 0 0 0.2rem rgba(4, 91, 193, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #f2f2f2;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.45em + 1.6rem + 2px);
  padding: 0.8rem 0.8rem;
  font-weight: 400;
  line-height: 1.45;
  color: #404040;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0.1875rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.45em + 1.6rem);
  padding: 0.8rem 0.8rem;
  line-height: 1.45;
  color: #404040;
  content: "Browse";
  background-color: #f2f2f2;
  border-left: inherit;
  border-radius: 0 0.1875rem 0.1875rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(4, 91, 193, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(4, 91, 193, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(4, 91, 193, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #045bc1;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #7bb7fc;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e1e1e1;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #045bc1;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #7bb7fc;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e1e1e1;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #045bc1;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #7bb7fc;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #e1e1e1;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e1e1e1;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #949494;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #949494;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #949494;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #7F7F7F;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e1e1e1;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #f2f2f2 #f2f2f2 #e1e1e1;
}

.nav-tabs .nav-link.disabled {
  color: #7F7F7F;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #666666;
  background-color: #fff;
  border-color: #e1e1e1 #e1e1e1 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.1875rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #045bc1;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.25858rem;
  padding-bottom: 0.25858rem;
  margin-right: 1rem;
  font-size: 1.333rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.333rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #000;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #000;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.25);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #000;
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a {
  color: #000;
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #000;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.7)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 3rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 3rem;
}

.card-header {
  padding: 1rem 3rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.1875rem - 1px) calc(0.1875rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1rem 3rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.1875rem - 1px) calc(0.1875rem - 1px);
}

.card-header-tabs {
  margin-right: -1.5rem;
  margin-bottom: -1rem;
  margin-left: -1.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 0;
  list-style: none;
  background-color: "transparent";
  border-radius: 0.1875rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #7F7F7F;
  content: "»";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #7F7F7F;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.1875rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  border: 1px solid #e1e1e1;
}

.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #000;
  border-color: #000;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 91, 193, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.page-item.disabled .page-link {
  color: #7F7F7F;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e1e1e1;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.333rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 900;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #045bc1;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #03438f;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 91, 193, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #666666;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #4d4d4d;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #7ed321;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #64a71a;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(126, 211, 33, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #00d6ff;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #00abcc;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 214, 255, 0.5);
}

.badge-warning {
  color: #fff;
  background-color: #f37f29;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #fff;
  background-color: #dd650c;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(243, 127, 41, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #d0021b;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #9d0214;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.5);
}

.badge-light {
  color: #000;
  background-color: #fafafa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #000;
  background-color: #e1e1e1;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #1c1c1c;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #030303;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(28, 28, 28, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f2f2f2;
  border-radius: 0.1875rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #022f64;
  background-color: #cddef3;
  border-color: #b9d1ee;
}

.alert-primary hr {
  border-top-color: #a4c4e9;
}

.alert-primary .alert-link {
  color: #011832;
}

.alert-secondary {
  color: #353535;
  background-color: #e0e0e0;
  border-color: #d4d4d4;
}

.alert-secondary hr {
  border-top-color: #c7c7c7;
}

.alert-secondary .alert-link {
  color: #1c1c1c;
}

.alert-success {
  color: #426e11;
  background-color: #e5f6d3;
  border-color: #dbf3c1;
}

.alert-success hr {
  border-top-color: #cfefac;
}

.alert-success .alert-link {
  color: #27420a;
}

.alert-info {
  color: #006f85;
  background-color: #ccf7ff;
  border-color: #b8f4ff;
}

.alert-info hr {
  border-top-color: #9ff0ff;
}

.alert-info .alert-link {
  color: #004452;
}

.alert-warning {
  color: #7e4215;
  background-color: #fde5d4;
  border-color: #fcdbc3;
}

.alert-warning hr {
  border-top-color: #fbccab;
}

.alert-warning .alert-link {
  color: #522b0e;
}

.alert-danger {
  color: #6c010e;
  background-color: #f6ccd1;
  border-color: #f2b8bf;
}

.alert-danger hr {
  border-top-color: #eea2ac;
}

.alert-danger .alert-link {
  color: #390107;
}

.alert-light {
  color: #828282;
  background-color: #fefefe;
  border-color: #fefefe;
}

.alert-light hr {
  border-top-color: #f1f1f1;
}

.alert-light .alert-link {
  color: dimgray;
}

.alert-dark {
  color: #0f0f0f;
  background-color: #d2d2d2;
  border-color: #bfbfbf;
}

.alert-dark hr {
  border-top-color: #b2b2b2;
}

.alert-dark .alert-link {
  color: black;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #f2f2f2;
  border-radius: 0.1875rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #045bc1;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #666666;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #666666;
  text-decoration: none;
  background-color: #fafafa;
}

.list-group-item-action:active {
  color: #666666;
  background-color: #f2f2f2;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #7F7F7F;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #045bc1;
  border-color: #045bc1;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.1875rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.1875rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #022f64;
  background-color: #b9d1ee;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #022f64;
  background-color: #a4c4e9;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #022f64;
  border-color: #022f64;
}

.list-group-item-secondary {
  color: #353535;
  background-color: #d4d4d4;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #353535;
  background-color: #c7c7c7;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #353535;
  border-color: #353535;
}

.list-group-item-success {
  color: #426e11;
  background-color: #dbf3c1;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #426e11;
  background-color: #cfefac;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #426e11;
  border-color: #426e11;
}

.list-group-item-info {
  color: #006f85;
  background-color: #b8f4ff;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #006f85;
  background-color: #9ff0ff;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #006f85;
  border-color: #006f85;
}

.list-group-item-warning {
  color: #7e4215;
  background-color: #fcdbc3;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7e4215;
  background-color: #fbccab;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7e4215;
  border-color: #7e4215;
}

.list-group-item-danger {
  color: #6c010e;
  background-color: #f2b8bf;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #6c010e;
  background-color: #eea2ac;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #6c010e;
  border-color: #6c010e;
}

.list-group-item-light {
  color: #828282;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #828282;
  background-color: #f1f1f1;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #828282;
  border-color: #828282;
}

.list-group-item-dark {
  color: #0f0f0f;
  background-color: #bfbfbf;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0f0f0f;
  background-color: #b2b2b2;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0f0f0f;
  border-color: #0f0f0f;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #7F7F7F;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.1875rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10040;
  width: 100vw;
  height: 100vh;
  background-color: #1c1c1c;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.9;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e1e1e1;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.45;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e1e1e1;
  border-bottom-right-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "PT Serif", Times, "Times New Roman", Georgia, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.45;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.1875rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "PT Serif", Times, "Times New Roman", Georgia, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.45;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.1875rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.1875rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.1875rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.1875rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #666666;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #045bc1 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #03438f !important;
}

.bg-secondary {
  background-color: #666666 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #4d4d4d !important;
}

.bg-success {
  background-color: #7ed321 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #64a71a !important;
}

.bg-info {
  background-color: #00d6ff !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #00abcc !important;
}

.bg-warning {
  background-color: #f37f29 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #dd650c !important;
}

.bg-danger {
  background-color: #d0021b !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #9d0214 !important;
}

.bg-light {
  background-color: #fafafa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e1e1e1 !important;
}

.bg-dark {
  background-color: #1c1c1c !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #030303 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e1e1e1 !important;
}

.border-top {
  border-top: 1px solid #e1e1e1 !important;
}

.border-right {
  border-right: 1px solid #e1e1e1 !important;
}

.border-bottom {
  border-bottom: 1px solid #e1e1e1 !important;
}

.border-left {
  border-left: 1px solid #e1e1e1 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #045bc1 !important;
}

.border-secondary {
  border-color: #666666 !important;
}

.border-success {
  border-color: #7ed321 !important;
}

.border-info {
  border-color: #00d6ff !important;
}

.border-warning {
  border-color: #f37f29 !important;
}

.border-danger {
  border-color: #d0021b !important;
}

.border-light {
  border-color: #fafafa !important;
}

.border-dark {
  border-color: #1c1c1c !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.1875rem !important;
}

.rounded {
  border-radius: 0.1875rem !important;
}

.rounded-top {
  border-top-left-radius: 0.1875rem !important;
  border-top-right-radius: 0.1875rem !important;
}

.rounded-right {
  border-top-right-radius: 0.1875rem !important;
  border-bottom-right-radius: 0.1875rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important;
}

.rounded-left {
  border-top-left-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important;
}

.rounded-lg {
  border-radius: 0.1875rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.8rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.8rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.8rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.8rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.8rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 5rem !important;
}

.m-8 {
  margin: 7.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 7.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 7.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 7.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 7.5rem !important;
}

.m-9 {
  margin: 10rem !important;
}

.mt-9,
.my-9 {
  margin-top: 10rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 10rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 10rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 10rem !important;
}

.m-10 {
  margin: 12.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 12.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 12.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 12.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 12.5rem !important;
}

.m-11 {
  margin: 15rem !important;
}

.mt-11,
.my-11 {
  margin-top: 15rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 15rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 15rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 15rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.8rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.8rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.8rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.8rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.8rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 5rem !important;
}

.p-8 {
  padding: 7.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 7.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 7.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 7.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 7.5rem !important;
}

.p-9 {
  padding: 10rem !important;
}

.pt-9,
.py-9 {
  padding-top: 10rem !important;
}

.pr-9,
.px-9 {
  padding-right: 10rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 10rem !important;
}

.pl-9,
.px-9 {
  padding-left: 10rem !important;
}

.p-10 {
  padding: 12.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 12.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 12.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 12.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 12.5rem !important;
}

.p-11 {
  padding: 15rem !important;
}

.pt-11,
.py-11 {
  padding-top: 15rem !important;
}

.pr-11,
.px-11 {
  padding-right: 15rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 15rem !important;
}

.pl-11,
.px-11 {
  padding-left: 15rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.8rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.8rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.8rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.8rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.8rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -4rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -4rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -4rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -4rem !important;
}

.m-n7 {
  margin: -5rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -5rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -5rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -5rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -5rem !important;
}

.m-n8 {
  margin: -7.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -7.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -7.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -7.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -7.5rem !important;
}

.m-n9 {
  margin: -10rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -10rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -10rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -10rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -10rem !important;
}

.m-n10 {
  margin: -12.5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -12.5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -12.5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -12.5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -12.5rem !important;
}

.m-n11 {
  margin: -15rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -15rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -15rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -15rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -15rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.8rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.8rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.8rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.8rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.8rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important;
  }
  .m-sm-8 {
    margin: 7.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 7.5rem !important;
  }
  .m-sm-9 {
    margin: 10rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 10rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 10rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 10rem !important;
  }
  .m-sm-10 {
    margin: 12.5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 12.5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 12.5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 12.5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 12.5rem !important;
  }
  .m-sm-11 {
    margin: 15rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 15rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 15rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 15rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 15rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.8rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.8rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.8rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.8rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.8rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important;
  }
  .p-sm-8 {
    padding: 7.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 7.5rem !important;
  }
  .p-sm-9 {
    padding: 10rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 10rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 10rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 10rem !important;
  }
  .p-sm-10 {
    padding: 12.5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 12.5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 12.5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 12.5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 12.5rem !important;
  }
  .p-sm-11 {
    padding: 15rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 15rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 15rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 15rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 15rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.8rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.8rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.8rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.8rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.8rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4rem !important;
  }
  .m-sm-n7 {
    margin: -5rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -5rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -5rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -5rem !important;
  }
  .m-sm-n8 {
    margin: -7.5rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -7.5rem !important;
  }
  .m-sm-n9 {
    margin: -10rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -10rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -10rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -10rem !important;
  }
  .m-sm-n10 {
    margin: -12.5rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -12.5rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -12.5rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -12.5rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -12.5rem !important;
  }
  .m-sm-n11 {
    margin: -15rem !important;
  }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -15rem !important;
  }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -15rem !important;
  }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -15rem !important;
  }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -15rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.8rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.8rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.8rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.8rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.8rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important;
  }
  .m-md-8 {
    margin: 7.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 7.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 7.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 7.5rem !important;
  }
  .m-md-9 {
    margin: 10rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 10rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 10rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 10rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 10rem !important;
  }
  .m-md-10 {
    margin: 12.5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 12.5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 12.5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 12.5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 12.5rem !important;
  }
  .m-md-11 {
    margin: 15rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 15rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 15rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 15rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 15rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.8rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.8rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.8rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.8rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.8rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important;
  }
  .p-md-8 {
    padding: 7.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 7.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 7.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 7.5rem !important;
  }
  .p-md-9 {
    padding: 10rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 10rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 10rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 10rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 10rem !important;
  }
  .p-md-10 {
    padding: 12.5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 12.5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 12.5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 12.5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 12.5rem !important;
  }
  .p-md-11 {
    padding: 15rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 15rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 15rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 15rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 15rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.8rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.8rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.8rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.8rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.8rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n6 {
    margin: -4rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4rem !important;
  }
  .m-md-n7 {
    margin: -5rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -5rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -5rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -5rem !important;
  }
  .m-md-n8 {
    margin: -7.5rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -7.5rem !important;
  }
  .m-md-n9 {
    margin: -10rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -10rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -10rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -10rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -10rem !important;
  }
  .m-md-n10 {
    margin: -12.5rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -12.5rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -12.5rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -12.5rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -12.5rem !important;
  }
  .m-md-n11 {
    margin: -15rem !important;
  }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -15rem !important;
  }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -15rem !important;
  }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -15rem !important;
  }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -15rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.8rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.8rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.8rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.8rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.8rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important;
  }
  .m-lg-8 {
    margin: 7.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 7.5rem !important;
  }
  .m-lg-9 {
    margin: 10rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 10rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 10rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 10rem !important;
  }
  .m-lg-10 {
    margin: 12.5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 12.5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 12.5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 12.5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 12.5rem !important;
  }
  .m-lg-11 {
    margin: 15rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 15rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 15rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 15rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 15rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.8rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.8rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.8rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.8rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.8rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important;
  }
  .p-lg-8 {
    padding: 7.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 7.5rem !important;
  }
  .p-lg-9 {
    padding: 10rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 10rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 10rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 10rem !important;
  }
  .p-lg-10 {
    padding: 12.5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 12.5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 12.5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 12.5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 12.5rem !important;
  }
  .p-lg-11 {
    padding: 15rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 15rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 15rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 15rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 15rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.8rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.8rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.8rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.8rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.8rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4rem !important;
  }
  .m-lg-n7 {
    margin: -5rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -5rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -5rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -5rem !important;
  }
  .m-lg-n8 {
    margin: -7.5rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -7.5rem !important;
  }
  .m-lg-n9 {
    margin: -10rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -10rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -10rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -10rem !important;
  }
  .m-lg-n10 {
    margin: -12.5rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -12.5rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -12.5rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -12.5rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -12.5rem !important;
  }
  .m-lg-n11 {
    margin: -15rem !important;
  }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -15rem !important;
  }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -15rem !important;
  }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -15rem !important;
  }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -15rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.8rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.8rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.8rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.8rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.8rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important;
  }
  .m-xl-8 {
    margin: 7.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 7.5rem !important;
  }
  .m-xl-9 {
    margin: 10rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 10rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 10rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 10rem !important;
  }
  .m-xl-10 {
    margin: 12.5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 12.5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 12.5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 12.5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 12.5rem !important;
  }
  .m-xl-11 {
    margin: 15rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 15rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 15rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 15rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 15rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.8rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.8rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.8rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.8rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.8rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important;
  }
  .p-xl-8 {
    padding: 7.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 7.5rem !important;
  }
  .p-xl-9 {
    padding: 10rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 10rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 10rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 10rem !important;
  }
  .p-xl-10 {
    padding: 12.5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 12.5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 12.5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 12.5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 12.5rem !important;
  }
  .p-xl-11 {
    padding: 15rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 15rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 15rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 15rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 15rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.8rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.8rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.8rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.8rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.8rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4rem !important;
  }
  .m-xl-n7 {
    margin: -5rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -5rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -5rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -5rem !important;
  }
  .m-xl-n8 {
    margin: -7.5rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -7.5rem !important;
  }
  .m-xl-n9 {
    margin: -10rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -10rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -10rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -10rem !important;
  }
  .m-xl-n10 {
    margin: -12.5rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -12.5rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -12.5rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -12.5rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -12.5rem !important;
  }
  .m-xl-n11 {
    margin: -15rem !important;
  }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -15rem !important;
  }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -15rem !important;
  }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -15rem !important;
  }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -15rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #045bc1 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #023876 !important;
}

.text-secondary {
  color: #666666 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #404040 !important;
}

.text-success {
  color: #7ed321 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #569117 !important;
}

.text-info {
  color: #00d6ff !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0096b3 !important;
}

.text-warning {
  color: #f37f29 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #c45a0b !important;
}

.text-danger {
  color: #d0021b !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #840111 !important;
}

.text-light {
  color: #fafafa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d4d4d4 !important;
}

.text-dark {
  color: #1c1c1c !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-body {
  color: #666666 !important;
}

.text-muted {
  color: #949494 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #949494;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e1e1e1 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e1e1e1;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e1e1e1;
  }
}

/*-----------------------------------------------
|   Sparrow Styles
-----------------------------------------------*/
/*-----------------------------------------------
|   Avatar
-----------------------------------------------*/
.avatar {
  width: 3.5rem;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
  /*-----------------------------------------------
  |   Egg shaped avatar
  -----------------------------------------------*/
}

.avatar.avatar-egg {
  height: 12.5rem;
  width: 11rem;
  border: 3px solid #fff;
  box-shadow: 0 0 0 0.5rem rgba(225, 225, 225, 0.7);
}

/*-----------------------------------------------
|   Badge
-----------------------------------------------*/
.badge-rotate {
  z-index: 1;
  transform: rotate(-45deg) translate(-30%, -50%);
  position: absolute;
  padding: 0.25rem;
  padding-left: 3rem;
  padding-right: 3rem;
  left: 0;
  top: 1.8rem;
}

/*-----------------------------------------------
|   Browser Fixing
-----------------------------------------------*/
.firefox .dropcap:first-letter {
  margin-top: 0.175em;
}

.edge .bg-holder .bg-video {
  height: 100%;
  width: auto;
}

.ie .align-items-center.minh-100vh {
  height: 100vh;
}

.ie .align-items-end.minh-100vh {
  height: 100vh;
}

.ie img {
  max-width: 100%;
}

/*-----------------------------------------------
|   Google Map
-----------------------------------------------*/
.googlemap {
  z-index: 1;
}

/*-----------------------------------------------
|   Buttons
-----------------------------------------------*/
.btn {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.btn-link {
  font-weight: 700;
}

.btn.btn-outline-light.border-2x {
  border-color: rgba(255, 255, 255, 0.5);
}

.btn-outline-light:hover, .btn-light, .btn-light:hover {
  color: #1c1c1c;
}

.btn-xs {
  padding: .2rem .8rem;
}

/*-----------------------------------------------
|   Documentation link and Prism
-----------------------------------------------*/
:not(pre) > code[class*='language-'], pre[class*='language-'] {
  background-color: #fafafa;
  border: 1px solid #e1e1e1;
  border-radius: 0.1875rem;
}

code[class*='language-'], pre[class*='language-'] {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.contains-anchor a {
  opacity: 0;
  transition-property: opacity;
}

.contains-anchor:hover a, .contains-anchor:focus a {
  opacity: 1;
  text-decoration: none;
}

.components-nav {
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.85rem;
}

/*-----------------------------------------------
|   Component examples
-----------------------------------------------*/
/*-----------------------------------------------
|   Borders
-----------------------------------------------*/
.component-example {
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.component-example [class^='border'] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  margin: .75rem;
  background-color: #fafafa;
}

/*-----------------------------------------------
|   Dropdowns
-----------------------------------------------*/
.dropdown-menu {
  font-size: 0.8rem;
}

.dropdown-item {
  transition: all 0.2s ease-in-out;
  font-weight: 700;
}

/*-----------------------------------------------
|   Fancynav
-----------------------------------------------*/
.fancynavbar {
  position: fixed;
  top: 0;
  z-index: 10000;
  width: 100%;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

@media (min-width: 992px) {
  .fancynavbar {
    right: 0;
    height: 100vh;
    width: 4.375rem;
  }
  .fancynavbar.fancynavbar-left {
    left: 0;
    right: auto;
  }
}

/*-----------------------------------------------
|   Fancy Navbar
-----------------------------------------------*/
.fancynavbar-togglerbar {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 991.98px) {
  .fancynavbar-togglerbar {
    align-items: center;
    height: 3rem;
  }
}

@media (min-width: 992px) {
  .fancynavbar-togglerbar {
    height: 100%;
    text-align: center;
    flex-direction: column;
    background-color: #000;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

/*-----------------------------------------------
|   Fancy Navbar Brand
-----------------------------------------------*/
.fancynavbar-brand {
  color: #fff;
  padding: 0.5rem 1rem;
}

.fancynavbar-brand-img {
  opacity: 1 !important;
}

/*-----------------------------------------------
|   Fancy Navbar Toggler
-----------------------------------------------*/
.fancynavbar-toggler {
  cursor: pointer;
  width: 4.375rem;
  height: 4.375rem;
  opacity: 1 !important;
}

@media (max-width: 991.98px) {
  .fancynavbar-toggler {
    transform: translateY(4%);
    order: 1;
  }
}

.fancynavbar-toggler-icon path {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

/*-----------------------------------------------
|   Fancy Navbar Addon
-----------------------------------------------*/
.fancynavbar-addon {
  padding-left: 0;
}

@media (max-width: 991.98px) {
  .fancynavbar-addon {
    display: flex;
    align-items: center;
    order: 0;
    position: absolute;
    right: 70px;
  }
}

.fancynavbar-addon-item {
  display: block;
  color: #fafafa;
  margin-top: 0.5rem;
  font-size: 1.333rem;
  transition: all 0.2s ease-in-out;
}

.fancynavbar-addon-item:hover, .fancynavbar-addon-item:focus {
  color: rgba(250, 250, 250, 0.75);
}

@media (max-width: 991.98px) {
  .fancynavbar-addon-item {
    margin-top: 0;
    padding: 0.5rem;
    font-size: 1rem;
  }
}

/*-----------------------------------------------
|   Fancy Navbar Collapse
-----------------------------------------------*/
.fancynavbar-collapse {
  height: 100vh;
  align-items: center;
  display: flex;
  overflow: auto;
  position: absolute;
  text-align: left;
  width: calc(50vw - 2.1875rem);
  right: calc(50% + 2.1875rem);
  transform: translateX(100%);
  top: 0;
  padding: 0;
  z-index: -1;
  backdrop-filter: blur(20px);
  background-color: #000;
  opacity: 0.92;
}

@media (max-width: 991.98px) {
  .fancynavbar-collapse {
    width: 100vw;
    top: 3rem;
    right: 0;
    height: calc(100vh - 3rem);
  }
}

@media (min-width: 992px) {
  .fancynavbar-collapse {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
}

.fancynavbar-left .fancynavbar-collapse {
  left: calc(50% + 2.1875rem);
  right: auto;
}

@media (max-width: 991.98px) {
  .fancynavbar-left .fancynavbar-collapse {
    left: 0;
  }
}

/*-----------------------------------------------
|   Fancy Navbar Nav
-----------------------------------------------*/
.fancynavbar-nav {
  height: auto;
  width: 100%;
  list-style-type: none;
  list-style-image: none;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto 0;
}

@media (min-width: 992px) {
  .fancynavbar-nav {
    padding-left: 0;
    padding-right: 0;
  }
}

/*-----------------------------------------------
|   Fancy Navbar Link
-----------------------------------------------*/
.fancynav-link {
  position: relative;
  display: block;
  font-size: 1.777rem;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .fancynav-link {
    font-size: 2.369rem;
  }
}

@media (min-width: 1200px) {
  .fancynav-link {
    font-size: 2.369rem;
  }
}

@media (min-width: 992px) {
  .fancynav-link {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.fancynav-link:before {
  content: '';
  position: absolute;
  height: 2px;
  width: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 2px;
  transition: all 0.5s;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 0;
}

.fancynav-link.fancy-dropdown-toggle .fancynav-link-content {
  padding-right: 3rem;
}

.fancynav-link.fancy-dropdown-toggle .fancynav-link-content:after {
  position: absolute;
  left: calc(100% - 2.1875rem);
  top: 0;
  transform: translateY(-50%);
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.33 7.03"><defs><style>.cls-1{fill:rgba(255, 255, 255, 0.85);}</style></defs><title>Asset 9sparrow</title><g id="Layer_2" data-name="Layer 2"><g id="Capa_1" data-name="Capa 1"><path class="cls-1" d="M6.17,7a.86.86,0,0,1-.61-.25L.25,1.47A.86.86,0,1,1,1.47.25L6.17,4.95,10.86.25a.86.86,0,0,1,1.22,1.22l-5.3,5.3A.86.86,0,0,1,6.17,7Z"/></g></g></svg>');
  text-align: center;
  color: white;
  width: 15px;
  height: 10px;
  background-color: transparent;
  z-index: 99;
}

.fancynav-link.show:before {
  width: 100%;
}

.fancynav-link.show .fancynav-link-content:after {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.5 9.41"><defs><style>.cls-1{fill:rgba(255, 255, 255, 0.85);}</style></defs><title>Asset 6sparrow</title><g id="Layer_2" data-name="Layer 2"><g id="Capa_1" data-name="Capa 1"><path class="cls-1" d="M8.25,0a1.15,1.15,0,0,1,.82.34l7.09,7.09a1.16,1.16,0,0,1-1.63,1.63L8.25,2.79,2,9.07A1.16,1.16,0,1,1,.34,7.43L7.43.34A1.15,1.15,0,0,1,8.25,0Z"/></g></g></svg>');
}

/*-----------------------------------------------
|   Fancy Navbar Link Content
-----------------------------------------------*/
.fancynav-link-content {
  color: rgba(255, 255, 255, 0.85);
  background-color: #000;
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  z-index: 1;
}

/*-----------------------------------------------
|   Fancy Navbar Item
-----------------------------------------------*/
.fancynav-item {
  overflow: hidden;
  transition: height 0.4s cubic-bezier(0.77, 0, 0.18, 1);
  height: 41px;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .fancynav-item {
    height: 54px;
  }
}

@media (min-width: 1200px) {
  .fancynav-item {
    height: 54px;
  }
}

.fancynav-item:hover .fancynav-link-content, .fancynav-item:focus .fancynav-link-content {
  text-decoration: none;
  color: #fff;
}

.fancynav-item:hover .fancynav-link:before, .fancynav-item:focus .fancynav-link:before {
  width: 100%;
}

/*-----------------------------------------------
|   Fancy Dropdown
-----------------------------------------------*/
.fancy-dropdown-menu {
  transition: height 0.2s cubic-bezier(0.77, 0, 0.18, 1);
}

@media (max-width: 991.98px) {
  .fancy-dropdown-menu {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-right: 1.8rem;
    padding-left: 1.8rem;
  }
}

@media (min-width: 992px) {
  .fancy-dropdown-menu {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

/*-----------------------------------------------
|   Fancy Dropdown Item
-----------------------------------------------*/
.fancy-dropdown-item {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 700;
  display: block;
  transition: all 0.2s ease-in-out;
}

.fancy-dropdown-item:hover, .fancy-dropdown-item:focus {
  color: #fff;
  text-decoration: none;
}

/*-----------------------------------------------
|   Adjusting the page Layout
-----------------------------------------------*/
@media (min-width: 992px) {
  .has-sidemenu .main {
    width: calc(100% - (4.375rem - 0.0625rem));
  }
}

@media (min-width: 992px) {
  .has-sidemenu .footer {
    width: calc(100% - 4.375rem);
  }
  .has-sidemenu .remodal {
    right: 2.1875rem;
  }
}

@media (min-width: 992px) {
  .has-sidemenu-left .main, .has-sidemenu-left .footer {
    margin-left: 4.375rem;
  }
}

/*-----------------------------------------------
|   IE Fix
-----------------------------------------------*/
.ie .fancynav .fancynavbar {
  height: 100vh;
}

@media (max-width: 991.98px) {
  .ie .fancynav .fancynavbar {
    height: calc(100vh - 3rem);
  }
}

.ie .fancynav .fancynavbar .fancynavbar-content {
  height: 100vh;
}

@media (max-width: 991.98px) {
  .ie .fancynav .fancynavbar .fancynavbar-content {
    height: calc(100vh - 3rem);
  }
}

/*-----------------------------------------------
|   Form
-----------------------------------------------*/
label {
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}

/*-----------------------------------------------
|   Input File
-----------------------------------------------*/
input.zinput-file[type='file'] {
  display: none;
}

.zinput-file + label svg {
  fill: currentColor;
  vertical-align: middle;
  margin-right: 0.5rem;
}

/*-----------------------------------------------
|   Checkbox iso toggle
-----------------------------------------------*/
.ios-toggle, .ios-toggle:active {
  height: 0;
  width: 0;
  opacity: 0;
}

.ios-toggle:checked + .checkbox-label {
  box-shadow: inset 0 0 0 1.25rem #045bc1, 0 0 0 0.0625rem #045bc1;
}

.ios-toggle:checked + .checkbox-label:before {
  left: calc(100% - 2rem);
  box-shadow: 0 0 0 0.0625rem transparent, -0.1875rem 0 0.1875rem rgba(0, 0, 0, 0.1);
}

.ios-toggle:checked + .checkbox-label:after {
  content: attr(data-on);
  left: 4.375rem;
  width: 2rem;
}

.checkbox-label {
  display: block;
  position: relative;
  padding: 0.625rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  width: 4.375rem;
  height: 2rem;
  border-radius: 3.125rem;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #045bc1, 0 0 0 0.0625rem #e1e1e1;
  transition: box-shadow 0.2s ease-in-out;
}

.checkbox-label:before {
  content: '';
  display: block;
  position: absolute;
  height: 2rem;
  width: 2rem;
  top: 0;
  left: 0;
  right: auto;
  background-color: white;
  border-radius: 3.125rem;
  box-shadow: 0.1875rem 0 0.1875rem rgba(0, 0, 0, 0.1), 0 0 0 0.0625rem #e1e1e1;
  transition: all 0.2s ease-in-out;
}

.checkbox-label:after {
  content: attr(data-off);
  display: block;
  position: absolute;
  top: 0;
  left: -18.75rem;
  padding: 0.625rem;
  height: 100%;
  width: 18.75rem;
  text-align: right;
  white-space: nowrap;
}

.checkbox-label.rounded {
  border-radius: 0.1875rem;
}

.checkbox-label.rounded:before {
  border-radius: 0.1875rem;
}

/*-----------------------------------------------
|   Checkbox and Radio
-----------------------------------------------*/
.zinput {
  position: relative;
}

.zinput label {
  display: inline-block;
  position: relative;
  padding-left: 3rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.zinput label:before {
  width: 2rem;
  height: 2rem;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
  content: '';
  border: 1px solid #e1e1e1;
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: inset 0 0 0 0 #045bc1, 0 0 0 0 #e1e1e1;
}

.zinput.zradio {
  padding: 1rem 0;
}

.zinput.zradio input[type='radio'] {
  opacity: 0;
  width: 0;
  height: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
}

.zinput.zradio input[type='radio']:checked + label:before {
  border-color: transparent;
}

.zinput.zradio input[type='radio'] + label:before {
  border-radius: 3.125rem;
}

.zinput.zradio input[type='radio']:checked + label:before {
  box-shadow: inset 0 0 0 0.5625rem #045bc1, 0 0 0 0 #045bc1;
}

.zinput.zcheckbox {
  padding: 1rem 0;
}

.zinput.zcheckbox input[type='checkbox'] {
  opacity: 0;
  width: 0;
  height: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
}

.zinput.zcheckbox input[type='checkbox']:checked + label:before {
  border-color: transparent;
}

.zinput.zcheckbox input[type='checkbox'] + label:before {
  border-radius: 0.1875rem;
}

.zinput.zcheckbox input[type='checkbox']:checked + label:before {
  box-shadow: inset 0 0 0 1.25rem #045bc1, 0 0 0 0 #045bc1;
}

.zinput svg {
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 50%;
  transform: translateY(-65%);
  left: 0.5rem;
  pointer-events: none;
}

.zinput svg path {
  stroke: #fff;
  stroke-width: 1.125rem;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

/*-----------------------------------------------
|   Input with icon
-----------------------------------------------*/
.input-iconic {
  position: relative;
}

.input-iconic button {
  outline: 0;
  background-color: transparent;
  color: #7F7F7F;
  border: 0;
}

.input-iconic .input-icon {
  position: absolute;
  z-index: 99;
  right: 1.8rem;
  top: 50%;
  transform: translateY(-50%);
}

.input-iconic .form-control {
  padding-right: 3rem;
  border-color: #e1e1e1;
}

/*-----------------------------------------------
|   Hover Box
-----------------------------------------------*/
.hoverbox {
  position: relative;
  overflow: hidden;
}

.hoverbox .hoverbox-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  margin: 0 !important;
}

.hoverbox .hoverbox-primary-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

.hoverbox:hover .hover-box-content-initial, .hoverbox:focus .hover-box-content-initial {
  opacity: 0;
}

.hoverbox:hover .hoverbox-content, .hoverbox:focus .hoverbox-content {
  opacity: 1;
}

.hoverbox:hover .as-hoverbox-content, .hoverbox:focus .as-hoverbox-content {
  z-index: 1;
}

.hoverbox-content-gradient {
  background: linear-gradient(transparent, #000);
}

/*-----------------------------------------------
|   Up/Down Indicator
-----------------------------------------------*/
.indicator-arrow {
  display: block;
  position: absolute;
  left: 50%;
  height: 0.625rem;
  width: 0.625rem;
  border-left: 2px solid #7F7F7F;
  border-top: 2px solid #7F7F7F;
  transform: translateX(-50%) rotate(45deg);
}

.indicator-arrow-one {
  top: 1.375rem;
}

.indicator-arrow-two {
  top: 1.75rem;
}

.indicator {
  position: absolute;
  height: 3rem;
  width: 3rem;
  left: 50%;
}

.indicator-up {
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.indicator-down {
  bottom: 2rem;
  transform: translateX(-50%) rotate(180deg);
}

/*-----------------------------------------------
|   Logos
-----------------------------------------------*/
.logo-sparrow {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.25rem 0 0 0.25rem;
  line-height: 1.875rem;
  text-indent: 0.4375rem;
  text-align: center;
  background-color: #fff;
  color: #000;
  display: inline-block;
  font-size: 3.75rem;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 900;
  overflow: hidden;
}

.logo {
  height: 2.5rem;
  width: auto;
}

.logo-grid {
  height: auto;
  width: 7rem;
}

/*-----------------------------------------------
|   Media Service
-----------------------------------------------*/
.media-img {
  width: 3.125rem;
}

/*-----------------------------------------------
|   Object fit and Z-index
-----------------------------------------------*/
.fit-cover {
  object-fit: cover;
}

.z-index-1 {
  z-index: 1;
}

/*-----------------------------------------------
|   Zanimation
-----------------------------------------------*/
*[data-zanim-trigger] {
  opacity: 0;
}

/*-----------------------------------------------
|   Puppeteer
-----------------------------------------------*/
.puppeteer .fancynavbar {
  display: none !important;
}

.puppeteer .main, .puppeteer .footer {
  width: 100vw !important;
}

.puppeteer *[data-zanim-trigger] {
  opacity: 1 !important;
}

/*-----------------------------------------------
|   Times Icon
-----------------------------------------------*/
.times-icon {
  position: relative;
  outline: none;
}

.times-icon:before, .times-icon:after {
  left: 0;
  position: absolute;
  content: '';
  width: 80%;
  height: 2px;
  background-color: #fff;
}

.times-icon:after {
  transform: rotate(45deg);
}

.times-icon:before {
  transform: rotate(-45deg);
}

.times-icon:hover, .times-icon:focus {
  outline: none;
}

.modal-xs {
  width: 12.5rem;
}

.modal-open {
  overflow: auto;
  padding-right: 0 !important;
}

.modal-open .sticky-top {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.modal.fade-in .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: scale(1);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade-in .modal-dialog {
    transition: none;
  }
}

[dir="rtl"] .modal-open {
  padding-left: 0 !important;
}

/*-----------------------------------------------
|   Navigation bar
-----------------------------------------------*/
.navbar {
  font-weight: 700;
  font-size: 0.8rem;
  letter-spacing: 0.01em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  transition: all 0.2s ease-in-out;
}

/*-----------------------------------------------
|   Pagination
-----------------------------------------------*/
.page-item {
  margin: 0 0.25rem;
}

/*-----------------------------------------------
|   Pre-loader
-----------------------------------------------*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  background-color: #fff;
  opacity: 1;
  transition: opacity 0.8s cubic-bezier(0.77, 0, 0.18, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .preloader {
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 7.5rem;
    padding-bottom: 4rem;
  }
}

.preloader.loaded {
  opacity: 0;
}

.line-scale-pulse-out-rapid div {
  background-color: #000;
  width: 0.125rem;
  border-radius: 0.1875rem;
}

/*-----------------------------------------------
|   Fancybox
-----------------------------------------------*/
.fancybox-caption-wrap, .fancybox-infobar {
  display: none;
}

.fancybox-toolbar {
  top: 1rem;
  right: 1rem;
  opacity: 1;
  visibility: visible !important;
}

@media (min-width: 992px) {
  .fancybox-toolbar {
    right: 5.375rem;
  }
}

.fancybox-active {
  overflow: auto !important;
  margin-right: 0 !important;
}

[dir="rtl"] .fancybox-active {
  margin-left: 0 !important;
}

/*-----------------------------------------------
|   Isotope
-----------------------------------------------*/
.sortable .menu {
  display: flex;
  flex-wrap: wrap;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-transform: lowercase;
  color: #666666;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 0.02rem;
}

.sortable .menu .item {
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
  transition: all 0.2s ease-in-out;
  margin-right: 0.25rem;
}

.sortable .menu .item:last-child {
  margin-right: 0;
}

.sortable .menu .item.active {
  border-color: currentColor;
  color: #000;
}

.sortable-container.sortable-container-gutter-fix {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

/*-----------------------------------------------
|   Lightbox
-----------------------------------------------*/
[data-lightbox] {
  cursor: zoom-in;
}

/*-----------------------------------------------
|   Plyr
-----------------------------------------------*/
.plyr {
  border-radius: 0.625rem;
}

.plyr__control--overlaid, .plyr__control--overlaid:hover, .plyr__control--overlaid:focus {
  background-color: #d0021b;
}

.plyr--video .plyr__controls .plyr__control.plyr__tab-focus, .plyr--video .plyr__controls .plyr__control:hover, .plyr--video .plyr__controls .plyr__control[aria-expanded=true], .plyr__menu__container label.plyr__control input[type=radio]:checked + span {
  background-color: #d0021b;
}

.plyr--full-ui input[type=range] {
  color: #d0021b;
}

.mobile.ios .player-wrapper {
  transform: none !important;
  opacity: 1;
  filter: none !important;
}

/*-----------------------------------------------
|   Progress bar and circle
-----------------------------------------------*/
.progress-circle {
  height: 15.625rem;
  position: relative;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.progress-circle .progressbar-text {
  text-align: center;
  color: #1c1c1c !important;
  margin-bottom: 0;
  line-height: 1;
}

.progress-circle .progressbar-text span {
  font-size: 1rem;
  display: block;
}

.progress-circle .progressbar-text .value {
  font-weight: 900;
  font-size: 4.199rem;
}

.progress-circle .progressbar-text b {
  font-size: 3.157rem;
  font-weight: 900;
}

.progress-circle svg {
  height: 100%;
  display: block;
}

.progress-line {
  position: relative;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.progress-line .progressbar-text {
  position: absolute;
  width: 100%;
  color: #1c1c1c;
  left: 0;
  top: -1rem;
  padding: 0;
  margin: 0;
}

.progress-line .progressbar-text .value {
  right: 0;
  position: absolute;
}

/*-----------------------------------------------
|   Typed Text
-----------------------------------------------*/
.typed-cursor {
  background: currentColor;
  width: 0.125rem !important;
  display: inline-block;
  text-indent: 10000rem;
  line-height: 1.4em;
}

/*-----------------------------------------------
|   Owl Carousel
-----------------------------------------------*/
.owl-theme .owl-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.owl-theme .owl-dots .owl-dot {
  padding: 1rem 0;
}

.owl-theme .owl-dots .owl-dot span {
  border-radius: 0.1875rem;
  width: 3rem;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.35);
  transition: background-color 0.4s ease-in-out;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #045bc1;
}

.owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot:focus span {
  background-color: #404040;
}

.owl-theme .owl-nav {
  margin-top: 0;
  cursor: pointer;
}

.owl-theme .owl-nav [class*=owl-] {
  opacity: 0;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  font-size: modular-scale(2);
  transition: opacity 0.4s ease-in-out;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  text-align: center;
  color: #666666;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.owl-theme .owl-nav [class*=owl-]:hover, .owl-theme .owl-nav [class*=owl-]:focus {
  background-color: #fff;
  color: #666666;
  opacity: 1 !important;
}

.owl-theme .owl-nav .owl-prev {
  left: 1.8rem;
}

.owl-theme .owl-nav .owl-next {
  right: 1.8rem;
}

.owl-theme:hover .owl-nav [class*=owl-], .owl-theme:focus .owl-nav [class*=owl-] {
  opacity: 0.5;
}

.owl-theme.nav-square .owl-nav {
  margin-top: 0;
}

.owl-theme.nav-square .owl-nav [class*=owl-] {
  border-radius: 0;
}

.owl-theme.owl-nav-outer .owl-nav .owl-prev {
  left: -4rem;
}

.owl-theme.owl-nav-outer .owl-nav .owl-next {
  right: -4rem;
}

.owl-theme.owl-dots-inner .owl-dots {
  bottom: 0;
}

.owl-theme.owl-dot-round .owl-dots .owl-dot span {
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 50%;
}

.owl-theme.owl-theme-info .owl-dots .owl-dot.active span {
  background-color: #00d6ff;
}

.owl-theme.owl-theme-success .owl-dots .owl-dot.active span {
  background-color: #7ed321;
}

.owl-theme.owl-theme-warning .owl-dots .owl-dot.active span {
  background-color: #f37f29;
}

.owl-theme.owl-theme-danger .owl-dots .owl-dot.active span {
  background-color: #d0021b;
}

.owl-theme.owl-eq-items .owl-stage {
  display: flex;
  flex-wrap: wrap;
}

.owl-theme.owl-eq-items .owl-item {
  display: flex;
  height: auto !important;
}

/*-----------------------------------------------
|   Owl Carousel Customization
-----------------------------------------------*/
.owl-loaded.owl-dots-inner .owl-dots {
  bottom: 1rem !important;
}

.owl-loaded .owl-dots .owl-dot span {
  position: relative;
  overflow: hidden;
  height: 0.25rem;
  width: 1.5625rem;
  display: block;
  margin: 0.5rem;
}

@media (min-width: 992px) {
  .owl-loaded .owl-dots .owl-dot span {
    width: 2.1875rem;
  }
}

@media (min-width: 1200px) {
  .owl-loaded .owl-dots .owl-dot span {
    width: 2.8125rem;
  }
}

.owl-loaded .owl-dots .owl-dot span:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  border-radius: 0.1875rem;
}

.owl-loaded .owl-dots .owl-dot.active span {
  background-color: rgba(255, 255, 255, 0.35) !important;
}

.owl-loaded .owl-dots .owl-dot.active span:after {
  animation: owlNavAnimate 5s;
}

@keyframes owlNavAnimate {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  85% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.owl-theme.owl-theme-vertical .owl-dots {
  height: 1.125rem;
  margin-top: 0 !important;
  transform: translate3d(50%, -50%, 0) rotate(90deg);
  top: 50%;
  right: 0.5625rem;
  margin-right: -1.8rem;
  transform-origin: center;
  text-align: center;
}

.owl-theme.owl-theme-vertical.owl-dots-inner .owl-dots {
  margin-right: 1.8rem;
}

/*-----------------------------------------------
|   Only Right nav
-----------------------------------------------*/
.owl-theme-nav-right .owl-nav .owl-prev {
  display: none !important;
}

/*-----------------------------------------------
|   Height fix
-----------------------------------------------*/
.owl-carousel.h-100 .owl-item, .owl-carousel.h-100 .owl-stage, .owl-carousel.h-100 .owl-stage-outer {
  height: 100%;
}

/*-----------------------------------------------
|   Accordion [Semantic UI]
-----------------------------------------------*/
.ui.styled.accordion {
  border-radius: 0.1875rem;
  box-shadow: none;
  border: 1px solid #e1e1e1;
}

.ui.styled.accordion .title {
  color: #1c1c1c;
  border-top: 1px solid #e1e1e1;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: 0;
  padding: 0.5rem 1rem;
  font-weight: 700;
  font-size: 1.333rem;
  transition: all 0.2s ease-in-out !important;
}

.ui.styled.accordion .title .dropdown.icon {
  margin-right: 0.75em;
  font-size: 0.75rem;
}

.ui.styled.accordion .title.active {
  color: #1c1c1c;
}

.ui.styled.accordion .content {
  padding: 1rem 3rem;
  color: #2b2b2b;
}

/*-----------------------------------------------
|   YT Player
-----------------------------------------------*/
.mb_YTPBar {
  display: none !important;
}

/*-----------------------------------------------
|   Process
-----------------------------------------------*/
.process-item {
  position: relative;
  display: inline-block;
}

.process-item .process-item-number {
  position: absolute;
  color: #e1e1e1;
  left: 0;
  top: 0;
  transform: translate(-127%, -21%);
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 800;
  background-color: white;
  font-size: 3.157rem;
}

@media (min-width: 576px) {
  .process-item .process-item-number {
    font-size: 5.584rem;
  }
}

.process-item.process-item-last .process-item-number:after {
  content: '';
  position: absolute;
  width: 1rem;
  background: #fff;
  height: 1000rem;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

/*-----------------------------------------------
|   Reboot
-----------------------------------------------*/
::selection {
  color: #fff;
  background-color: #d0021b;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

section {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (min-width: 992px) {
  section {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}

input,
button,
select,
optgroup,
textarea,
label,
.alert,
.badge,
.blockquote-footer,
.btn,
.navbar,
.pagination,
.valid-feedback,
.invalid-feedback {
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/*-----------------------------------------------
|   Showcase
-----------------------------------------------*/
.devices-wrapper {
  transform: translateY(-50%) rotate(45deg);
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: 40%;
  width: 56.25rem;
  height: 56.25rem;
}

@media (min-width: 576px) {
  .devices-wrapper {
    left: 70%;
    width: 31.25rem;
    height: 31.25rem;
  }
}

@media (min-width: 768px) {
  .devices-wrapper {
    left: 60%;
    width: 75rem;
    height: 75rem;
  }
}

@media (min-width: 992px) {
  .devices-wrapper {
    left: 50%;
    width: 68.75rem;
    height: 68.75rem;
  }
}

@media (min-width: 1200px) {
  .devices-wrapper {
    left: 40%;
    width: 75rem;
    height: 75rem;
  }
}

@media (min-width: 1400px) {
  .devices-wrapper {
    left: 25%;
    width: 100rem;
    height: 100rem;
  }
}

.devices-wrapper .device {
  width: 100% !important;
  border-radius: 0.5rem;
  border: 0.25rem solid rgba(190, 190, 190, 0.1);
  box-shadow: 0.125rem 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.3);
  width: 6.25rem;
  transition: transform 2s cubic-bezier(0.77, 0, 0.18, 1);
}

.devices-wrapper [class*="iphone-"] {
  width: 10%;
  position: absolute;
}

.devices-wrapper [class*="iphone--l-"] {
  width: 29.5%;
  position: absolute;
}

.devices-wrapper [class*="ipad--l-"] {
  width: 28%;
  position: absolute;
}

.devices-wrapper [class*="ipad--p-"] {
  width: 21%;
  position: absolute;
}

.devices-wrapper [class*="macbook-"] {
  width: 44%;
  position: absolute;
}

.devices-wrapper .iphone-1 {
  left: 27%;
  top: 40%;
}

.devices-wrapper .iphone-2 {
  left: 69%;
  top: 40%;
}

.devices-wrapper .iphone-3 {
  left: 55%;
  top: -14%;
}

.devices-wrapper .iphone--l-1 {
  left: 42%;
  top: 93%;
}

.devices-wrapper .iphone-4 {
  left: 88%;
  top: 63%;
}

.devices-wrapper .ipad--l-1 {
  left: -3%;
  top: 40%;
}

.devices-wrapper .ipad--l-2 {
  left: 39%;
  top: 40%;
}

.devices-wrapper .ipad--l-3 {
  left: 81%;
  top: 40%;
}

.devices-wrapper .ipad--p-1 {
  left: 69%;
  top: 10%;
}

.devices-wrapper .macbook-1 {
  left: 42%;
  top: 63%;
}

.devices-wrapper .macbook-2 {
  left: 22%;
  top: 10%;
}

/*-----------------------------------------------
|   Tabs
-----------------------------------------------*/
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-3.125rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(3.125rem);
  }
}

.fancy-tab {
  display: flex;
  flex-direction: column;
}

.fancy-tab .nav-bar {
  display: flex;
  justify-content: flex-start;
  position: relative;
  flex-flow: row wrap;
  position: relative;
  border-bottom: 2px solid #fafafa;
}

.fancy-tab .nav-bar .tab-indicator {
  content: '';
  position: absolute;
  display: block;
  bottom: -0.125rem;
  height: 0.125rem;
  background-color: #045bc1;
  transition: left 0.4s ease-in-out, right 0.2s ease-in-out;
}

.fancy-tab .nav-bar .tab-indicator.transition-reverse {
  transition: left 0.2s ease-in-out, right 0.4s ease-in-out;
}

.fancy-tab .nav-bar.nav-bar-right {
  justify-content: flex-end;
}

.fancy-tab .nav-bar.nav-bar-center {
  justify-content: center;
}

.fancy-tab .nav-bar .nav-bar-item {
  padding: 0.5rem 1.8rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
}

.fancy-tab .nav-bar .nav-bar-item.active {
  color: #045bc1;
}

.fancy-tab .tab-contents {
  margin-top: 1.875rem;
  position: relative;
}

.fancy-tab .tab-contents .tab-content {
  backface-visibility: hidden;
}

.fancy-tab .tab-contents .tab-content:not(.active) {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 1;
  animation: fadeOutRight .25s ease forwards;
}

.fancy-tab .tab-contents .tab-content.active {
  animation: fadeInLeft .5s ease forwards;
}

/*-----------------------------------------------
|   Drop cap
-----------------------------------------------*/
.dropcap::first-letter {
  font-size: 3em;
  float: left;
  line-height: .92;
  margin-right: 0.375rem;
}

/*-----------------------------------------------
|   Letter Spacing
-----------------------------------------------*/
.ls {
  letter-spacing: 0.04em;
}

.ls-2 {
  letter-spacing: 0.25em;
}

/*-----------------------------------------------
|   List
-----------------------------------------------*/
.bullet-inside {
  list-style-position: inside;
}

.style-check li {
  position: relative;
  list-style-type: none;
  padding-left: .25rem;
}

.style-check li:before {
  content: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZGF0YS1mYS1wcm9jZXNzZWQ9IiIgZGF0YS1wcmVmaXg9ImZhbCIgZGF0YS1pY29uPSJjaGVjayIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hlY2sgZmEtdy0xNCIgc3R5bGU9ImZvbnQtc2l6ZTogNDhweDsiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTQxMy41MDUgOTEuOTUxTDEzMy40OSAzNzEuOTY2bC05OC45OTUtOTguOTk1Yy00LjY4Ni00LjY4Ni0xMi4yODQtNC42ODYtMTYuOTcxIDBMNi4yMTEgMjg0LjI4NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDExOC43OTQgMTE4Ljc5NGM0LjY4NiA0LjY4NiAxMi4yODQgNC42ODYgMTYuOTcxIDBsMjk5LjgxMy0yOTkuODEzYzQuNjg2LTQuNjg2IDQuNjg2LTEyLjI4NCAwLTE2Ljk3MWwtMTEuMzE0LTExLjMxNGMtNC42ODYtNC42ODYtMTIuMjg0LTQuNjg2LTE2Ljk3IDB6IiBjbGFzcz0iIj48L3BhdGg+PC9zdmc+);
  padding-right: 0.1875rem;
  position: absolute;
  top: 0.0625rem;
  left: -1rem;
  background-repeat: no-repeat;
  width: 1rem;
}

/*-----------------------------------------------
|   Horizontal rules
-----------------------------------------------*/
.hr-short {
  width: 4.2rem;
}

/*-----------------------------------------------
|   Blockquote
-----------------------------------------------*/
.blockquote-content {
  font-style: italic;
  position: relative;
}

.blockquote-content:before {
  position: absolute;
  left: -3rem;
  top: -0.5rem;
  line-height: 1;
  content: "“";
  display: inline-block;
  color: #e1e1e1;
  font-size: 5.584rem;
}

.blockquote-footer {
  font-weight: 700;
}

/*-----------------------------------------------
|   Backgrounds
-----------------------------------------------*/
.bg-holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  background-repeat: no-repeat;
}

.bg-holder.bg-right {
  left: auto;
  right: 0;
}

.bg-holder.overlay:before {
  position: absolute;
  content: '';
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg-holder.overlay-primary:before {
  background: rgba(4, 91, 193, 0.4);
}

.bg-holder.overlay-info:before {
  background: rgba(0, 214, 255, 0.4);
}

.bg-holder.overlay-success:before {
  background: rgba(126, 211, 33, 0.4);
}

.bg-holder.overlay-warning:before {
  background: rgba(243, 127, 41, 0.4);
}

.bg-holder.overlay-danger:before {
  background: rgba(208, 2, 27, 0.4);
}

.bg-holder.overlay-0:before {
  background: rgba(0, 0, 0, 0.7);
}

.bg-holder.overlay-1:before {
  background: rgba(0, 0, 0, 0.55);
}

.bg-holder.overlay-2:before {
  background: rgba(0, 0, 0, 0.4);
}

.bg-holder .bg-video {
  position: absolute;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  min-width: 100%;
}

.bg-holder .bg-youtube {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.overlay-z-index-1:before {
  z-index: 1;
}

.bg-fixed {
  background-attachment: fixed;
}

.has-parallax {
  position: relative;
  overflow: hidden;
}

.bg-glass {
  background-color: rgba(255, 255, 255, 0.9);
}

/*-----------------------------------------------
|   Background Colors [Grays]
-----------------------------------------------*/
.bg-100 {
  background-color: #fafafa !important;
}

a.bg-100:hover, a.bg-100:focus,
button.bg-100:hover,
button.bg-100:focus {
  background-color: #e1e1e1 !important;
}

.bg-200 {
  background-color: #f2f2f2 !important;
}

a.bg-200:hover, a.bg-200:focus,
button.bg-200:hover,
button.bg-200:focus {
  background-color: #d9d9d9 !important;
}

.bg-300 {
  background-color: #e1e1e1 !important;
}

a.bg-300:hover, a.bg-300:focus,
button.bg-300:hover,
button.bg-300:focus {
  background-color: #c8c8c8 !important;
}

.bg-400 {
  background-color: #bebebe !important;
}

a.bg-400:hover, a.bg-400:focus,
button.bg-400:hover,
button.bg-400:focus {
  background-color: #a5a5a5 !important;
}

.bg-500 {
  background-color: #949494 !important;
}

a.bg-500:hover, a.bg-500:focus,
button.bg-500:hover,
button.bg-500:focus {
  background-color: #7b7b7b !important;
}

.bg-600 {
  background-color: #7F7F7F !important;
}

a.bg-600:hover, a.bg-600:focus,
button.bg-600:hover,
button.bg-600:focus {
  background-color: #666666 !important;
}

.bg-700 {
  background-color: #666666 !important;
}

a.bg-700:hover, a.bg-700:focus,
button.bg-700:hover,
button.bg-700:focus {
  background-color: #4d4d4d !important;
}

.bg-800 {
  background-color: #555555 !important;
}

a.bg-800:hover, a.bg-800:focus,
button.bg-800:hover,
button.bg-800:focus {
  background-color: #3c3c3c !important;
}

.bg-900 {
  background-color: #404040 !important;
}

a.bg-900:hover, a.bg-900:focus,
button.bg-900:hover,
button.bg-900:focus {
  background-color: #272727 !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-1000 {
  background-color: #2b2b2b !important;
}

a.bg-1000:hover, a.bg-1000:focus,
button.bg-1000:hover,
button.bg-1000:focus {
  background-color: #121212 !important;
}

.bg-1100 {
  background-color: #1c1c1c !important;
}

a.bg-1100:hover, a.bg-1100:focus,
button.bg-1100:hover,
button.bg-1100:focus {
  background-color: #030303 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

/*---------------------------------------------------------------
|   Borders & Radius
---------------------------------------------------------------*/
/*---------------------------------------------------------------
    |   Borders
    ---------------------------------------------------------------*/
.border {
  border: 1px solid #e1e1e1 !important;
}

.border-top {
  border-top: 1px solid #e1e1e1 !important;
}

.border-right {
  border-right: 1px solid #e1e1e1 !important;
}

.border-bottom {
  border-bottom: 1px solid #e1e1e1 !important;
}

.border-left {
  border-left: 1px solid #e1e1e1 !important;
}

.border-x {
  border-left: 1px solid #e1e1e1 !important;
  border-right: 1px solid #e1e1e1 !important;
}

.border-y {
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
}

.border-dashed {
  border: 1px dashed #e1e1e1 !important;
}

.border-dashed-top {
  border-top: 1px dashed #e1e1e1 !important;
}

.border-dashed-right {
  border-right: 1px dashed #e1e1e1 !important;
}

.border-dashed-bottom {
  border-bottom: 1px dashed #e1e1e1 !important;
}

.border-dashed-left {
  border-left: 1px dashed #e1e1e1 !important;
}

.border-dashed-x {
  border-left: 1px dashed #e1e1e1 !important;
  border-right: 1px dashed #e1e1e1 !important;
}

.border-dashed-y {
  border-top: 1px dashed #e1e1e1 !important;
  border-bottom: 1px dashed #e1e1e1 !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-top-0 {
  border-top-width: 0 !important;
}

.border-right-0 {
  border-right-width: 0 !important;
}

.border-bottom-0 {
  border-bottom-width: 0 !important;
}

.border-left-0 {
  border-left-width: 0 !important;
}

.border-x-0 {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

.border-y-0 {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}

/*---------------------------------------------------------------
    |   Radius
    ---------------------------------------------------------------*/
.rounded {
  border-radius: 0.1875rem !important;
}

.rounded-top {
  border-top-left-radius: 0.1875rem !important;
  border-top-right-radius: 0.1875rem !important;
}

.rounded-right {
  border-top-right-radius: 0.1875rem !important;
  border-bottom-right-radius: 0.1875rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important;
}

.rounded-left {
  border-top-left-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important;
}

.rounded-soft {
  border-radius: 0.625rem !important;
}

.rounded-capsule {
  border-radius: 3.125rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

@media (min-width: 576px) {
  /*---------------------------------------------------------------
    |   Borders
    ---------------------------------------------------------------*/
  .border-sm {
    border: 1px solid #e1e1e1 !important;
  }
  .border-sm-top {
    border-top: 1px solid #e1e1e1 !important;
  }
  .border-sm-right {
    border-right: 1px solid #e1e1e1 !important;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #e1e1e1 !important;
  }
  .border-sm-left {
    border-left: 1px solid #e1e1e1 !important;
  }
  .border-sm-x {
    border-left: 1px solid #e1e1e1 !important;
    border-right: 1px solid #e1e1e1 !important;
  }
  .border-sm-y {
    border-top: 1px solid #e1e1e1 !important;
    border-bottom: 1px solid #e1e1e1 !important;
  }
  .border-sm-dashed {
    border: 1px dashed #e1e1e1 !important;
  }
  .border-sm-dashed-top {
    border-top: 1px dashed #e1e1e1 !important;
  }
  .border-sm-dashed-right {
    border-right: 1px dashed #e1e1e1 !important;
  }
  .border-sm-dashed-bottom {
    border-bottom: 1px dashed #e1e1e1 !important;
  }
  .border-sm-dashed-left {
    border-left: 1px dashed #e1e1e1 !important;
  }
  .border-sm-dashed-x {
    border-left: 1px dashed #e1e1e1 !important;
    border-right: 1px dashed #e1e1e1 !important;
  }
  .border-sm-dashed-y {
    border-top: 1px dashed #e1e1e1 !important;
    border-bottom: 1px dashed #e1e1e1 !important;
  }
  .border-sm-0 {
    border-width: 0 !important;
  }
  .border-sm-top-0 {
    border-top-width: 0 !important;
  }
  .border-sm-right-0 {
    border-right-width: 0 !important;
  }
  .border-sm-bottom-0 {
    border-bottom-width: 0 !important;
  }
  .border-sm-left-0 {
    border-left-width: 0 !important;
  }
  .border-sm-x-0 {
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }
  .border-sm-y-0 {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }
  /*---------------------------------------------------------------
    |   Radius
    ---------------------------------------------------------------*/
  .rounded-sm {
    border-radius: 0.1875rem !important;
  }
  .rounded-sm-top {
    border-top-left-radius: 0.1875rem !important;
    border-top-right-radius: 0.1875rem !important;
  }
  .rounded-sm-right {
    border-top-right-radius: 0.1875rem !important;
    border-bottom-right-radius: 0.1875rem !important;
  }
  .rounded-sm-bottom {
    border-bottom-right-radius: 0.1875rem !important;
    border-bottom-left-radius: 0.1875rem !important;
  }
  .rounded-sm-left {
    border-top-left-radius: 0.1875rem !important;
    border-bottom-left-radius: 0.1875rem !important;
  }
  .rounded-sm-soft {
    border-radius: 0.625rem !important;
  }
  .rounded-sm-capsule {
    border-radius: 3.125rem !important;
  }
  .rounded-sm-circle {
    border-radius: 50% !important;
  }
}

@media (min-width: 768px) {
  /*---------------------------------------------------------------
    |   Borders
    ---------------------------------------------------------------*/
  .border-md {
    border: 1px solid #e1e1e1 !important;
  }
  .border-md-top {
    border-top: 1px solid #e1e1e1 !important;
  }
  .border-md-right {
    border-right: 1px solid #e1e1e1 !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #e1e1e1 !important;
  }
  .border-md-left {
    border-left: 1px solid #e1e1e1 !important;
  }
  .border-md-x {
    border-left: 1px solid #e1e1e1 !important;
    border-right: 1px solid #e1e1e1 !important;
  }
  .border-md-y {
    border-top: 1px solid #e1e1e1 !important;
    border-bottom: 1px solid #e1e1e1 !important;
  }
  .border-md-dashed {
    border: 1px dashed #e1e1e1 !important;
  }
  .border-md-dashed-top {
    border-top: 1px dashed #e1e1e1 !important;
  }
  .border-md-dashed-right {
    border-right: 1px dashed #e1e1e1 !important;
  }
  .border-md-dashed-bottom {
    border-bottom: 1px dashed #e1e1e1 !important;
  }
  .border-md-dashed-left {
    border-left: 1px dashed #e1e1e1 !important;
  }
  .border-md-dashed-x {
    border-left: 1px dashed #e1e1e1 !important;
    border-right: 1px dashed #e1e1e1 !important;
  }
  .border-md-dashed-y {
    border-top: 1px dashed #e1e1e1 !important;
    border-bottom: 1px dashed #e1e1e1 !important;
  }
  .border-md-0 {
    border-width: 0 !important;
  }
  .border-md-top-0 {
    border-top-width: 0 !important;
  }
  .border-md-right-0 {
    border-right-width: 0 !important;
  }
  .border-md-bottom-0 {
    border-bottom-width: 0 !important;
  }
  .border-md-left-0 {
    border-left-width: 0 !important;
  }
  .border-md-x-0 {
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }
  .border-md-y-0 {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }
  /*---------------------------------------------------------------
    |   Radius
    ---------------------------------------------------------------*/
  .rounded-md {
    border-radius: 0.1875rem !important;
  }
  .rounded-md-top {
    border-top-left-radius: 0.1875rem !important;
    border-top-right-radius: 0.1875rem !important;
  }
  .rounded-md-right {
    border-top-right-radius: 0.1875rem !important;
    border-bottom-right-radius: 0.1875rem !important;
  }
  .rounded-md-bottom {
    border-bottom-right-radius: 0.1875rem !important;
    border-bottom-left-radius: 0.1875rem !important;
  }
  .rounded-md-left {
    border-top-left-radius: 0.1875rem !important;
    border-bottom-left-radius: 0.1875rem !important;
  }
  .rounded-md-soft {
    border-radius: 0.625rem !important;
  }
  .rounded-md-capsule {
    border-radius: 3.125rem !important;
  }
  .rounded-md-circle {
    border-radius: 50% !important;
  }
}

@media (min-width: 992px) {
  /*---------------------------------------------------------------
    |   Borders
    ---------------------------------------------------------------*/
  .border-lg {
    border: 1px solid #e1e1e1 !important;
  }
  .border-lg-top {
    border-top: 1px solid #e1e1e1 !important;
  }
  .border-lg-right {
    border-right: 1px solid #e1e1e1 !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #e1e1e1 !important;
  }
  .border-lg-left {
    border-left: 1px solid #e1e1e1 !important;
  }
  .border-lg-x {
    border-left: 1px solid #e1e1e1 !important;
    border-right: 1px solid #e1e1e1 !important;
  }
  .border-lg-y {
    border-top: 1px solid #e1e1e1 !important;
    border-bottom: 1px solid #e1e1e1 !important;
  }
  .border-lg-dashed {
    border: 1px dashed #e1e1e1 !important;
  }
  .border-lg-dashed-top {
    border-top: 1px dashed #e1e1e1 !important;
  }
  .border-lg-dashed-right {
    border-right: 1px dashed #e1e1e1 !important;
  }
  .border-lg-dashed-bottom {
    border-bottom: 1px dashed #e1e1e1 !important;
  }
  .border-lg-dashed-left {
    border-left: 1px dashed #e1e1e1 !important;
  }
  .border-lg-dashed-x {
    border-left: 1px dashed #e1e1e1 !important;
    border-right: 1px dashed #e1e1e1 !important;
  }
  .border-lg-dashed-y {
    border-top: 1px dashed #e1e1e1 !important;
    border-bottom: 1px dashed #e1e1e1 !important;
  }
  .border-lg-0 {
    border-width: 0 !important;
  }
  .border-lg-top-0 {
    border-top-width: 0 !important;
  }
  .border-lg-right-0 {
    border-right-width: 0 !important;
  }
  .border-lg-bottom-0 {
    border-bottom-width: 0 !important;
  }
  .border-lg-left-0 {
    border-left-width: 0 !important;
  }
  .border-lg-x-0 {
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }
  .border-lg-y-0 {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }
  /*---------------------------------------------------------------
    |   Radius
    ---------------------------------------------------------------*/
  .rounded-lg {
    border-radius: 0.1875rem !important;
  }
  .rounded-lg-top {
    border-top-left-radius: 0.1875rem !important;
    border-top-right-radius: 0.1875rem !important;
  }
  .rounded-lg-right {
    border-top-right-radius: 0.1875rem !important;
    border-bottom-right-radius: 0.1875rem !important;
  }
  .rounded-lg-bottom {
    border-bottom-right-radius: 0.1875rem !important;
    border-bottom-left-radius: 0.1875rem !important;
  }
  .rounded-lg-left {
    border-top-left-radius: 0.1875rem !important;
    border-bottom-left-radius: 0.1875rem !important;
  }
  .rounded-lg-soft {
    border-radius: 0.625rem !important;
  }
  .rounded-lg-capsule {
    border-radius: 3.125rem !important;
  }
  .rounded-lg-circle {
    border-radius: 50% !important;
  }
}

@media (min-width: 1200px) {
  /*---------------------------------------------------------------
    |   Borders
    ---------------------------------------------------------------*/
  .border-xl {
    border: 1px solid #e1e1e1 !important;
  }
  .border-xl-top {
    border-top: 1px solid #e1e1e1 !important;
  }
  .border-xl-right {
    border-right: 1px solid #e1e1e1 !important;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #e1e1e1 !important;
  }
  .border-xl-left {
    border-left: 1px solid #e1e1e1 !important;
  }
  .border-xl-x {
    border-left: 1px solid #e1e1e1 !important;
    border-right: 1px solid #e1e1e1 !important;
  }
  .border-xl-y {
    border-top: 1px solid #e1e1e1 !important;
    border-bottom: 1px solid #e1e1e1 !important;
  }
  .border-xl-dashed {
    border: 1px dashed #e1e1e1 !important;
  }
  .border-xl-dashed-top {
    border-top: 1px dashed #e1e1e1 !important;
  }
  .border-xl-dashed-right {
    border-right: 1px dashed #e1e1e1 !important;
  }
  .border-xl-dashed-bottom {
    border-bottom: 1px dashed #e1e1e1 !important;
  }
  .border-xl-dashed-left {
    border-left: 1px dashed #e1e1e1 !important;
  }
  .border-xl-dashed-x {
    border-left: 1px dashed #e1e1e1 !important;
    border-right: 1px dashed #e1e1e1 !important;
  }
  .border-xl-dashed-y {
    border-top: 1px dashed #e1e1e1 !important;
    border-bottom: 1px dashed #e1e1e1 !important;
  }
  .border-xl-0 {
    border-width: 0 !important;
  }
  .border-xl-top-0 {
    border-top-width: 0 !important;
  }
  .border-xl-right-0 {
    border-right-width: 0 !important;
  }
  .border-xl-bottom-0 {
    border-bottom-width: 0 !important;
  }
  .border-xl-left-0 {
    border-left-width: 0 !important;
  }
  .border-xl-x-0 {
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }
  .border-xl-y-0 {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }
  /*---------------------------------------------------------------
    |   Radius
    ---------------------------------------------------------------*/
  .rounded-xl {
    border-radius: 0.1875rem !important;
  }
  .rounded-xl-top {
    border-top-left-radius: 0.1875rem !important;
    border-top-right-radius: 0.1875rem !important;
  }
  .rounded-xl-right {
    border-top-right-radius: 0.1875rem !important;
    border-bottom-right-radius: 0.1875rem !important;
  }
  .rounded-xl-bottom {
    border-bottom-right-radius: 0.1875rem !important;
    border-bottom-left-radius: 0.1875rem !important;
  }
  .rounded-xl-left {
    border-top-left-radius: 0.1875rem !important;
    border-bottom-left-radius: 0.1875rem !important;
  }
  .rounded-xl-soft {
    border-radius: 0.625rem !important;
  }
  .rounded-xl-capsule {
    border-radius: 3.125rem !important;
  }
  .rounded-xl-circle {
    border-radius: 50% !important;
  }
}

/*-----------------------------------------------
|   Safari clipping fix
-----------------------------------------------*/
.overflow-hidden[class*='rounded'] {
  mask-image: radial-gradient(#fff, #000);
}

/*---------------------------------------------------------------
|   Border Color
---------------------------------------------------------------*/
.border-primary {
  border-color: #045bc1 !important;
}

.border-secondary {
  border-color: #666666 !important;
}

.border-success {
  border-color: #7ed321 !important;
}

.border-info {
  border-color: #00d6ff !important;
}

.border-warning {
  border-color: #f37f29 !important;
}

.border-danger {
  border-color: #d0021b !important;
}

.border-light {
  border-color: #fafafa !important;
}

.border-dark {
  border-color: #1c1c1c !important;
}

.border-100 {
  border-color: #fafafa !important;
}

.border-200 {
  border-color: #f2f2f2 !important;
}

.border-300 {
  border-color: #e1e1e1 !important;
}

.border-400 {
  border-color: #bebebe !important;
}

.border-500 {
  border-color: #949494 !important;
}

.border-600 {
  border-color: #7F7F7F !important;
}

.border-700 {
  border-color: #666666 !important;
}

.border-800 {
  border-color: #555555 !important;
}

.border-900 {
  border-color: #404040 !important;
}

.border-black {
  border-color: #000 !important;
}

.border-1000 {
  border-color: #2b2b2b !important;
}

.border-1100 {
  border-color: #1c1c1c !important;
}

.border-white {
  border-color: #fff !important;
}

.border-2x {
  border-width: 2px !important;
}

/*-----------------------------------------------
|   Height
-----------------------------------------------*/
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.min-vh-100 {
  min-height: 100vh;
}

.min-vh-75 {
  min-height: 75vh;
}

.min-vh-50 {
  min-height: 50vh;
}

.min-vh-25 {
  min-height: 25vh;
}

.max-vh-100 {
  max-height: 100vh;
}

.max-vh-75 {
  max-height: 75vh;
}

.max-vh-50 {
  max-height: 50vh;
}

.max-vh-25 {
  max-height: 25vh;
}

.vh-100 {
  height: 100vh;
}

.vh-75 {
  height: 75vh;
}

.vh-50 {
  height: 50vh;
}

.vh-25 {
  height: 25vh;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .min-vh-sm-100 {
    min-height: 100vh;
  }
  .min-vh-sm-75 {
    min-height: 75vh;
  }
  .min-vh-sm-50 {
    min-height: 50vh;
  }
  .min-vh-sm-25 {
    min-height: 25vh;
  }
  .max-vh-sm-100 {
    max-height: 100vh;
  }
  .max-vh-sm-75 {
    max-height: 75vh;
  }
  .max-vh-sm-50 {
    max-height: 50vh;
  }
  .max-vh-sm-25 {
    max-height: 25vh;
  }
  .vh-sm-100 {
    height: 100vh;
  }
  .vh-sm-75 {
    height: 75vh;
  }
  .vh-sm-50 {
    height: 50vh;
  }
  .vh-sm-25 {
    height: 25vh;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .min-vh-md-100 {
    min-height: 100vh;
  }
  .min-vh-md-75 {
    min-height: 75vh;
  }
  .min-vh-md-50 {
    min-height: 50vh;
  }
  .min-vh-md-25 {
    min-height: 25vh;
  }
  .max-vh-md-100 {
    max-height: 100vh;
  }
  .max-vh-md-75 {
    max-height: 75vh;
  }
  .max-vh-md-50 {
    max-height: 50vh;
  }
  .max-vh-md-25 {
    max-height: 25vh;
  }
  .vh-md-100 {
    height: 100vh;
  }
  .vh-md-75 {
    height: 75vh;
  }
  .vh-md-50 {
    height: 50vh;
  }
  .vh-md-25 {
    height: 25vh;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .min-vh-lg-100 {
    min-height: 100vh;
  }
  .min-vh-lg-75 {
    min-height: 75vh;
  }
  .min-vh-lg-50 {
    min-height: 50vh;
  }
  .min-vh-lg-25 {
    min-height: 25vh;
  }
  .max-vh-lg-100 {
    max-height: 100vh;
  }
  .max-vh-lg-75 {
    max-height: 75vh;
  }
  .max-vh-lg-50 {
    max-height: 50vh;
  }
  .max-vh-lg-25 {
    max-height: 25vh;
  }
  .vh-lg-100 {
    height: 100vh;
  }
  .vh-lg-75 {
    height: 75vh;
  }
  .vh-lg-50 {
    height: 50vh;
  }
  .vh-lg-25 {
    height: 25vh;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .min-vh-xl-100 {
    min-height: 100vh;
  }
  .min-vh-xl-75 {
    min-height: 75vh;
  }
  .min-vh-xl-50 {
    min-height: 50vh;
  }
  .min-vh-xl-25 {
    min-height: 25vh;
  }
  .max-vh-xl-100 {
    max-height: 100vh;
  }
  .max-vh-xl-75 {
    max-height: 75vh;
  }
  .max-vh-xl-50 {
    max-height: 50vh;
  }
  .max-vh-xl-25 {
    max-height: 25vh;
  }
  .vh-xl-100 {
    height: 100vh;
  }
  .vh-xl-75 {
    height: 75vh;
  }
  .vh-xl-50 {
    height: 50vh;
  }
  .vh-xl-25 {
    height: 25vh;
  }
}

/*-----------------------------------------------
|   Font family, Position and Overflow [NTR]
-----------------------------------------------*/
.overflow-hidden, .overflow-hidden-x {
  overflow-x: hidden;
}

.overflow-hidden, .overflow-hidden-y {
  overflow-y: hidden;
}

/*-----------------------------------------------
|   Position
-----------------------------------------------*/
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
}

@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
}

@media (min-width: 992px) {
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
}

/*-----------------------------------------------
|   For Stickyfill on IE
-----------------------------------------------*/
.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

/*-----------------------------------------------
|   Position
-----------------------------------------------*/
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  position: relative;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

.a-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/*-----------------------------------------------
|   Font Sizes
-----------------------------------------------*/
.fs--1 {
  font-size: 0.75rem;
}

.fs-0 {
  font-size: 1rem;
}

.fs-1 {
  font-size: 1.333rem;
}

.fs-2 {
  font-size: 1.777rem;
}

.fs-3 {
  font-size: 2.369rem;
}

.fs-4 {
  font-size: 3.157rem;
}

.fs-5 {
  font-size: 4.199rem;
}

.fs-6 {
  font-size: 5.584rem;
}

.fs-7 {
  font-size: 7.427rem;
}

.fs-8 {
  font-size: 9.878rem;
}

@media (min-width: 576px) {
  .fs-sm--1 {
    font-size: 0.75rem;
  }
  .fs-sm-0 {
    font-size: 1rem;
  }
  .fs-sm-1 {
    font-size: 1.333rem;
  }
  .fs-sm-2 {
    font-size: 1.777rem;
  }
  .fs-sm-3 {
    font-size: 2.369rem;
  }
  .fs-sm-4 {
    font-size: 3.157rem;
  }
  .fs-sm-5 {
    font-size: 4.199rem;
  }
  .fs-sm-6 {
    font-size: 5.584rem;
  }
  .fs-sm-7 {
    font-size: 7.427rem;
  }
  .fs-sm-8 {
    font-size: 9.878rem;
  }
}

@media (min-width: 768px) {
  .fs-md--1 {
    font-size: 0.75rem;
  }
  .fs-md-0 {
    font-size: 1rem;
  }
  .fs-md-1 {
    font-size: 1.333rem;
  }
  .fs-md-2 {
    font-size: 1.777rem;
  }
  .fs-md-3 {
    font-size: 2.369rem;
  }
  .fs-md-4 {
    font-size: 3.157rem;
  }
  .fs-md-5 {
    font-size: 4.199rem;
  }
  .fs-md-6 {
    font-size: 5.584rem;
  }
  .fs-md-7 {
    font-size: 7.427rem;
  }
  .fs-md-8 {
    font-size: 9.878rem;
  }
}

@media (min-width: 992px) {
  .fs-lg--1 {
    font-size: 0.75rem;
  }
  .fs-lg-0 {
    font-size: 1rem;
  }
  .fs-lg-1 {
    font-size: 1.333rem;
  }
  .fs-lg-2 {
    font-size: 1.777rem;
  }
  .fs-lg-3 {
    font-size: 2.369rem;
  }
  .fs-lg-4 {
    font-size: 3.157rem;
  }
  .fs-lg-5 {
    font-size: 4.199rem;
  }
  .fs-lg-6 {
    font-size: 5.584rem;
  }
  .fs-lg-7 {
    font-size: 7.427rem;
  }
  .fs-lg-8 {
    font-size: 9.878rem;
  }
}

@media (min-width: 1200px) {
  .fs-xl--1 {
    font-size: 0.75rem;
  }
  .fs-xl-0 {
    font-size: 1rem;
  }
  .fs-xl-1 {
    font-size: 1.333rem;
  }
  .fs-xl-2 {
    font-size: 1.777rem;
  }
  .fs-xl-3 {
    font-size: 2.369rem;
  }
  .fs-xl-4 {
    font-size: 3.157rem;
  }
  .fs-xl-5 {
    font-size: 4.199rem;
  }
  .fs-xl-6 {
    font-size: 5.584rem;
  }
  .fs-xl-7 {
    font-size: 7.427rem;
  }
  .fs-xl-8 {
    font-size: 9.878rem;
  }
}

/*-----------------------------------------------
|   Text style
-----------------------------------------------*/
/*   Font Weight */
.font-weight-thin {
  font-weight: 100 !important;
}

.font-weight-extra-light {
  font-weight: 200 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

/* Decoration */
.text-underline {
  text-decoration: underline;
  text-decoration-skip: ink;
}

.text-smallcaps {
  font-variant: small-caps;
}

/*-----------------------------------------------
|   Font family
-----------------------------------------------*/
.text-serif {
  font-family: "PT Serif", Times, "Times New Roman", Georgia, serif;
}

.text-sans-serif {
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/*-----------------------------------------------
|   Contextual colors
-----------------------------------------------*/
.text-100 {
  color: #fafafa !important;
}

a.text-100:hover, a.text-100:focus {
  color: #d4d4d4 !important;
}

.text-200 {
  color: #f2f2f2 !important;
}

a.text-200:hover, a.text-200:focus {
  color: #cccccc !important;
}

.text-300 {
  color: #e1e1e1 !important;
}

a.text-300:hover, a.text-300:focus {
  color: #bbbbbb !important;
}

.text-400 {
  color: #bebebe !important;
}

a.text-400:hover, a.text-400:focus {
  color: #989898 !important;
}

.text-500 {
  color: #949494 !important;
}

a.text-500:hover, a.text-500:focus {
  color: #6e6e6e !important;
}

.text-600 {
  color: #7F7F7F !important;
}

a.text-600:hover, a.text-600:focus {
  color: #595959 !important;
}

.text-700 {
  color: #666666 !important;
}

a.text-700:hover, a.text-700:focus {
  color: #404040 !important;
}

.text-800 {
  color: #555555 !important;
}

a.text-800:hover, a.text-800:focus {
  color: #2f2f2f !important;
}

.text-900 {
  color: #404040 !important;
}

a.text-900:hover, a.text-900:focus {
  color: #1a1a1a !important;
}

.text-black {
  color: #000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-1000 {
  color: #2b2b2b !important;
}

a.text-1000:hover, a.text-1000:focus {
  color: #050505 !important;
}

.text-1100 {
  color: #1c1c1c !important;
}

a.text-1100:hover, a.text-1100:focus {
  color: black !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

/*-----------------------------------------------
|   Colors for social icons
-----------------------------------------------*/
.text-facebook {
  color: #3b5998;
}

.text-google-plus {
  color: #db4437;
}

.text-twitter {
  color: #1da1f2;
}

.text-linkedin {
  color: #0077B5;
}

.text-youtube {
  color: #ff0002;
}

/*-----------------------------------------------
|   User Styles
-----------------------------------------------*/
.indicator-custom-float {
  z-index: 1050;
}

.indicator {
  display: none;
  position: absolute;
  height: 3rem;
  width: 3rem;
  left: 50%;
}

@media (min-width: 1200px) {
  .indicator {
    display: block;
    position: absolute;
    height: 3rem;
    width: 3rem;
    left: 50%;
  }
}

@media (max-width: 1199.98px) {
  .portfolio-index {
    padding-top: 0 !important;
  }
}
